import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  Menu, 
  Home, 
  Archive, 
  User, 
  ShoppingBag, 
  MessageSquare, 
  Rocket, 
  Book,
  Linkedin,
  Instagram
} from 'lucide-react';
import NavItem from './navigation/NavItem';
import MenuToggle from './navigation/MenuToggle';
import { NavigationItem } from './navigation/types';
import BlxxnkIcon from './navigation/BlxxnkIcon';

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isActive = (path: string) => location.pathname === path;

  const navigationItems: NavigationItem[] = [
    { path: '/', icon: Home, label: 'Home Base' },
    { path: '/vault', icon: Archive, label: 'The Vault' },
    { path: '/blxxnk', icon: BlxxnkIcon, label: 'BLXXNK', isCustomIcon: true },
    { path: '/operations', icon: User, label: 'About Me' },
    { path: '/expeditions', icon: Rocket, label: 'Orbital Operations' },
    { path: '/artifacts', icon: ShoppingBag, label: 'Artifacts' },
    { path: '/chronicles', icon: Book, label: 'Chronicles' },
    { path: '/contact', icon: MessageSquare, label: 'Contact HQ' },
  ];

  const handleNavigation = (path: string) => {
    navigate(path);
    setIsMenuOpen(false);
  };

  const XLogo = () => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 1200 1227"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="text-gray-400 group-hover:text-purple-400 transition-colors"
    >
      <path
        d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
        fill="currentColor"
      />
    </svg>
  );

  const socialLinks = [
    {
      href: "https://www.linkedin.com/in/shallah-jones-375a22126/",
      icon: Linkedin,
      label: "LinkedIn"
    },
    {
      href: "https://www.instagram.com/_boxlord/",
      icon: Instagram,
      label: "Instagram"
    },
    {
      href: "https://x.com/_boxlord",
      icon: XLogo,
      label: "X",
      isCustomIcon: true
    }
  ];

  return (
    <>
      <MenuToggle isOpen={isMenuOpen} onToggle={() => setIsMenuOpen(!isMenuOpen)} />

      <nav className={`fixed top-0 left-0 h-screen bg-black/90 backdrop-blur-lg border-r border-white/10 flex flex-col items-center py-8 z-40 transition-transform duration-300 ${
        isMenuOpen ? 'translate-x-0 w-full md:w-16' : '-translate-x-full md:translate-x-0 w-16'
      }`}>
        <div className="text-purple-500 hover:text-purple-400 transition-colors cursor-pointer">
          <Menu size={24} />
        </div>
        
        <div className="flex flex-col space-y-6 flex-grow">
          {navigationItems.map((item) => (
            <NavItem 
              key={item.path}
              icon={item.isCustomIcon ? <item.icon /> : <item.icon size={20} />}
              label={item.label}
              isActive={isActive(item.path)}
              onClick={() => handleNavigation(item.path)}
            />
          ))}
        </div>

        <div className="flex flex-col space-y-4 mb-8">
          {socialLinks.map((link) => (
            <NavItem
              key={link.href}
              icon={link.isCustomIcon ? <link.icon /> : <link.icon size={20} />}
              label={link.label}
              isActive={false}
              onClick={() => window.open(link.href, '_blank', 'noopener,noreferrer')}
            />
          ))}
        </div>
      </nav>
    </>
  );
};

export default Navigation;