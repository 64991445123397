import React from 'react';

const BlxxnkIcon: React.FC = () => {
  return (
    <svg 
      width="24" 
      height="24" 
      viewBox="0 0 24 24" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      className="current-color"
      stroke="currentColor"
      strokeWidth="1.5"
    >
      {/* Box outline */}
      <rect 
        x="5" 
        y="4" 
        width="14" 
        height="16" 
        rx="2" 
        fill="none" 
      />
      
      {/* Eyes */}
      <circle cx="9" cy="9" r="1.5" fill="none" />
      <circle cx="15" cy="9" r="1.5" fill="none" />
      
      {/* X eyes */}
      <line x1="8" y1="8" x2="10" y2="10" strokeLinecap="round" />
      <line x1="10" y1="8" x2="8" y2="10" strokeLinecap="round" />
      <line x1="14" y1="8" x2="16" y2="10" strokeLinecap="round" />
      <line x1="16" y1="8" x2="14" y2="10" strokeLinecap="round" />
      
      {/* Smile */}
      <path d="M10 15C10 15 11 16 12 16C13 16 14 15 14 15" strokeLinecap="round" />
    </svg>
  );
};

export default BlxxnkIcon;